// import React, { PureComponent } from "react";
// import {
//   PieChart,
//   Pie,
//   Sector,
//   Cell,
//   ResponsiveContainer,
//   Tooltip,
// } from "recharts";

// // const data = [
// //   { name: "Wheat", value: 400 },
// //   { name: "Corn", value: 300 },
// //   { name: "Canola", value: 300 },
// //   { name: "Lentils", value: 200 },
// // ];

// const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

// const RADIAN = Math.PI / 180;

// export default function MyPieChart({ width, height, rawData }) {
//   const data = parseData(rawData);
//   function parseData(rawData) {
//     var result = [];

//     for (const row of rawData) {
//       result.push({ name: row[0], value: parseFloat(row[1]) });
//     }
//     return result;
//   }
//   console.log(data);

//   const renderCustomizedLabel = ({
//     cx,
//     cy,
//     midAngle,
//     innerRadius,
//     outerRadius,
//     percent,
//     index,
//   }) => {
//     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//     const x = cx + radius * Math.cos(-midAngle * RADIAN);
//     const y = cy + radius * Math.sin(-midAngle * RADIAN);

//     return (
//       <text
//         x={x}
//         y={y}
//         fill="white"
//         textAnchor={x > cx ? "start" : "end"}
//         dominantBaseline="central"
//       >
//         {data[index].name + ` ${(percent * 100).toFixed(0)}%`}
//       </text>
//     );
//   };

//   return (
//     <ResponsiveContainer width={width} height={height}>
//       <PieChart width={width} height={height}>
//         <Pie
//           data={data}
//           cx="50%"
//           cy="50%"
//           labelLine={false}
//           label={renderCustomizedLabel}
//           outerRadius={170}
//           fill="#8884d8"
//           dataKey="value"
//         >
//           {data.map((entry, index) => (
//             <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//           ))}
//           <Tooltip />
//         </Pie>
//       </PieChart>
//     </ResponsiveContainer>
//   );
// }

// import Chart from "chart.js/auto";
// import { getRelativePosition } from "chart.js/helpers";

// function EnergyUsePieChart({ width, weight, rawData }) {
//   function parseData(rawData) {
//     var labels = [];
//     var values = [];

//     for (var row of rawData) {
//       labels.push(row[0]);
//       values.push(row[1]);
//     }
//     return [labels, values];
//   }
//   const [labels, values] = parseData(rawData);

//   const data = {
//     labels: labels,
//     datasets: [
//       {
//         label: "Dataset 1",
//         data: values,
//         backgroundColor: Object.values([
//           "#4dc9f6",
//           "#f67019",
//           "#f53794",
//           "#537bc4",
//           "#acc236",
//           "#166a8f",
//           "#00a950",
//           "#58595b",
//           "#8549ba",
//         ]),
//       },
//     ],
//   };
//   const ctx = canvasRef.current.getContext("2d");
//   const chart = new Chart(ctx, {
//     type: "line",
//     data: data,
//     options: {
//       onClick: (e) => {
//         const canvasPosition = getRelativePosition(e, chart);

//         // Substitute the appropriate scale IDs
//         const dataX = chart.scales.x.getValueForPixel(canvasPosition.x);
//         const dataY = chart.scales.y.getValueForPixel(canvasPosition.y);
//       },
//     },
//   });

//   return chart;
// }
import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

function EnergyUsePieChart({ width, height, rawData }) {
  const canvasRef = useRef(null);

  function parseData(rawData) {
    const labels = [];
    const values = [];

    for (const row of rawData) {
      labels.push(row[0]);
      values.push(row[1]);
    }
    return [labels, values];
  }

  useEffect(() => {
    const [labels, values] = parseData(rawData);

    const data = {
      labels: labels,
      datasets: [
        {
          label: "Dataset 1",
          data: values,
          backgroundColor: [
            "#4dc9f6",
            "#f67019",
            "#f53794",
            "#537bc4",
            "#acc236",
            "#166a8f",
            "#00a950",
            "#58595b",
            "#8549ba",
          ],
        },
      ],
    };

    const ctx = canvasRef.current.getContext("2d");

    const chart = new Chart(ctx, {
      type: "pie",
      data: data,
      options: {
        onClick: (e) => {
          const points = chart.getElementsAtEventForMode(
            e,
            "nearest",
            { intersect: true },
            false
          );

          if (points.length) {
            const firstPoint = points[0];
            const label = chart.data.labels[firstPoint.index];
            const value =
              chart.data.datasets[firstPoint.datasetIndex].data[
                firstPoint.index
              ];

            console.log(`Label: ${label}, Value: ${value}`);
          }
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [rawData]);

  return <canvas ref={canvasRef} width={width} height={height} />;
}

export default EnergyUsePieChart;
