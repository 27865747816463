import { Divider } from "@mui/material";
import BooleanQuestion from "../FormInputElements/BooleanQuestion";
import MultiChoiceQuestion from "../FormInputElements/MultiChoiceQuestion";
import NumberQuestion from "../FormInputElements/NumberQuestion";
import { useState, useEffect } from "react";
// import FormSelectCultivationTractorField from "../FormInputElements/FormSelectCultivationTractorField";
// import FormSelectPlantingMachine from "../FormInputElements/FormSelectPlantingMachine";
// import FormSelectFertilizerTractorField from "../FormInputElements/FormSelectFertilizerTractorField";
// import FormSelectSprayerField from "../FormInputElements/FormSelectSprayerField";

import CultivationsForm from "./FieldOperationsSubForms/CultivationsForm";
import PlantingForm from "./FieldOperationsSubForms/PlantingForm";
import FertilizerApplicationsForm from "./FieldOperationsSubForms/FertilizerApplicationsForm";
import FertilizerRatesForm from "./FieldOperationsSubForms/FertilizerRatesForm";
import PesticidesForm from "./FieldOperationsSubForms/PesticidesForm";

import { FertilizerTypes } from "../../Assets/DataTypes";

import FertilizerTableField from "../../Components/FormInputElements/FertilizerTableField";

import { CropYearContext, FarmContext } from "../../App";
import { useContext } from "react";

export default function FieldOperationsForm({ LowerPanel, panelControls }) {
  const cropyearContext = useContext(CropYearContext);
  const farmContext = useContext(FarmContext);

  useEffect(() => {
    const element = document.getElementById("scrollableDiv");
    element.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className=" w-full h-full">
      <h3 className="text-[rgb(102,102,102)] text-[30px]">Field Operations</h3>
      <Divider sx={{ marginBottom: 3 }} />

      <CultivationsForm />
      <PlantingForm />
      <FertilizerApplicationsForm />
      <FertilizerRatesForm />
      <PesticidesForm />

      <LowerPanel
        activeStep={panelControls.activeStep}
        handleBack={panelControls.handleBack}
        handleNext={panelControls.handleNext}
        steps={panelControls.steps}
        handleComplete={panelControls.handleComplete}
        completedSteps={panelControls.completedSteps}
        totalSteps={panelControls.totalSteps}
        isInputValid={() => true}
      />
    </div>
  );
}
