import React, { useState, useRef, useEffect, useContext } from "react";
import { GoogleMap, LoadScript, Polygon, Marker } from "@react-google-maps/api";
import { FieldContext } from "../App";
import { IconButton } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import PolylineIcon from "@mui/icons-material/Polyline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
const containerStyle = {
  width: "100%",
  height: "100%",
};

// const center = {
//   lat: -3.745,
//   lng: -38.523,
// };

const MapComponent = ({
  GOOGLE_APIKEY,
  address,
  drawFieldBoundaries,
  setDrawFieldBoundaries,
  isTypingAddress,
}) => {
  const fieldContext = useContext(FieldContext);
  const controlDivRef = useRef(null);
  const [map, setMap] = useState(null);
  const [path, setPath] = useState(fieldContext.state.boundariesArray);
  const [drawing, setDrawing] = useState(false);

  const [center, setCenter] = useState({
    lat: 57.534346,
    lng: -101.925502,
  });

  const handleMapClick = (event) => {
    if (drawing) {
      setPath([...path, { lat: event.latLng.lat(), lng: event.latLng.lng() }]);
    }
  };

  const mapOptions = {
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    rotateControl: false,
    zoomControl: true,
    mapTypeId: "hybrid", // Set default to satellite view
    tilt: 0,
  };

  useEffect(() => {
    // console.log(fieldContext.state);
    if (fieldContext.state.hasBoundaries) {
      setPath(fieldContext.state.boundariesArray);
    }
  }, [fieldContext]);

  useEffect(() => {
    if (map) {
      if (center.lat === 57.534346 && center.lng === -101.925502) {
        map.setZoom(4);
      } else {
        map.setZoom(16);
      }

      map.panTo(center);
    }
  }, [map, center]);

  useEffect(() => {
    async function fetchAddress() {
      if (GOOGLE_APIKEY && !isTypingAddress) {
        // console.log(address, GOOGLE_APIKEY, "xxxx");
        if (fieldContext.state.boundariesArray.length > 2) {
          setCenter(calculateCentroid(fieldContext.state.boundariesArray));
        } else {
          const data = await getCoordinatesFromAddress(address, GOOGLE_APIKEY);
          setCenter(data);
        }
      }
    }

    if (address.trim().toLowerCase() !== "canada") fetchAddress();
  }, [address, GOOGLE_APIKEY, isTypingAddress]);

  useEffect(() => {
    if (map && controlDivRef.current) {
      if (drawFieldBoundaries === "true" || drawFieldBoundaries == true) {
        if (
          controlDivRef.current &&
          !map.controls[window.google.maps.ControlPosition.TOP_LEFT]
            .getArray()
            .includes(controlDivRef.current)
        ) {
          map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
            controlDivRef.current
          );
        }
        console.log("show");
      } else {
        console.log("hide");
        setPath([]);
        setDrawing(false);
        if (
          controlDivRef.current &&
          map.controls[window.google.maps.ControlPosition.TOP_LEFT]
            .getArray()
            .includes(controlDivRef.current)
        ) {
          map.controls[window.google.maps.ControlPosition.TOP_LEFT].removeAt(
            map.controls[window.google.maps.ControlPosition.TOP_LEFT]
              .getArray()
              .indexOf(controlDivRef.current)
          );
        }
      }
    }
  }, [map, drawFieldBoundaries]);

  const handleVertexDragEnd = (event, index) => {
    const newPath = [...path];
    newPath[index] = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setPath(newPath);
  };

  const handleStartDrawing = () => {
    // setPath([]);
    setDrawing(true);
  };

  const handleStopDrawing = () => {
    setDrawing(!drawing);
  };

  const handleReset = () => {
    // setDrawFieldBoundaries("false");
    setPath([]);
    fieldContext.setter({
      ...fieldContext.state,
      hasBoundaries: false,
      boundariesArray: [],
      fieldBoundariesSize: 0,
    });
  };

  const handleUndoVertex = () => {
    if (path.length > 1) {
      let pathCopy = path.slice();
      pathCopy.pop();
      setPath(pathCopy);
    }
  };

  const handleSave = () => {
    if (path.length > 2) {
      const areaInSquareMeters =
        window.google.maps.geometry.spherical.computeArea(path);

      const areaInAcres = areaInSquareMeters * 0.000247105;

      // console.log("Polygon coordinates:", path);
      fieldContext.setter({
        ...fieldContext.state,
        hasBoundaries: true,
        boundariesArray: path,
        fieldBoundariesSize: areaInAcres,
      });
      setDrawing(false);
      // console.log("Area in acres:", areaInAcres);
    } else {
      alert("Polygon is too small to compute an area.");
    }
  };

  const handleAddressChange = (event) => {
    // setAddress(event.target.value);
  };

  // const handleAddressSubmit = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const location = await getCoordinatesFromAddress(address, GOOGLE_APIKEY);
  //     map.panTo(location);
  //     map.setZoom(15);
  //   } catch (error) {
  //     console.error("Geocoding error:", error);
  //   }
  // };
  if (!GOOGLE_APIKEY) return;

  return (
    <LoadScript googleMapsApiKey={GOOGLE_APIKEY} libraries={["places"]}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onClick={handleMapClick}
        onLoad={(map) => {
          setMap(map);
        }}
        options={mapOptions}
      >
        {window.google && path.length > 0 ? (
          <>
            <Polygon
              paths={path}
              options={{
                fillColor: "orange",
                fillOpacity: 0.5,
                strokeColor: "orange",
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
            {path.map((vertex, index) => (
              <Marker
                key={index}
                position={vertex}
                draggable={true}
                onDragEnd={(event) => handleVertexDragEnd(event, index)}
                icon={{
                  url: "data:image/svg+xml;charset=UTF-8,%3Csvg height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='6' fill='blue'/%3E%3C/svg%3E",
                  scaledSize: new window.google.maps.Size(12, 12),
                  anchor: new window.google.maps.Point(6, 6),
                }}
              />
            ))}
          </>
        ) : (
          <>
            <Polygon
              paths={[]}
              options={{
                fillColor: "orange",
                fillOpacity: 0.5,
                strokeColor: "orange",
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          </>
        )}
      </GoogleMap>

      <div
        ref={controlDivRef}
        style={{
          marginLeft: "15px",
          marginTop: "15px",
          background: "#FFFFFF",
          display: "flex",
        }}
      >
        <IconButton onClick={handleStartDrawing}>
          <div
            className={`${
              !drawing && path.length === 0 ? "animate-bounce" : ""
            }  ${drawing && "text-[#F15D22]"}`}
          >
            <Tooltip title="Draw Boundaries">
              <PolylineIcon />
            </Tooltip>
          </div>
        </IconButton>
        <Divider orientation="vertical" variant="middle" flexItem />
        <IconButton onClick={handleUndoVertex}>
          <Tooltip title="Undo">
            <UndoIcon />
          </Tooltip>
        </IconButton>
        <Divider orientation="vertical" variant="middle" flexItem />
        <IconButton onClick={handleStopDrawing}>
          <Tooltip title={drawing ? "Stop Drawing" : "Resume Drawing"}>
            {drawing ? <StopCircleIcon /> : <PlayCircleIcon />}
          </Tooltip>
        </IconButton>
        <Divider orientation="vertical" variant="middle" flexItem />
        <IconButton onClick={handleReset}>
          <Tooltip title="Reset Drawing">
            <ClearIcon />
          </Tooltip>
        </IconButton>
        <Divider orientation="vertical" variant="middle" flexItem />

        <IconButton onClick={handleSave}>
          <div
            className={`${drawing && path.length > 2 ? "text-[#F15D22]" : ""}`}
          >
            <Tooltip title="Save">
              <SaveIcon />
            </Tooltip>
          </div>
        </IconButton>
      </div>
    </LoadScript>
  );
};

export default MapComponent;

const getCoordinatesFromAddress = async (address, apiKey) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`
  );
  const data = await response.json();

  if (data.status === "OK") {
    const location = data.results[0].geometry.location;
    return { lat: location.lat, lng: location.lng };
  } else {
    throw new Error("Geocoding failed");
  }
};

function calculateCentroid(vertices) {
  let sumLat = 0,
    sumLng = 0;

  vertices.forEach((vertex) => {
    sumLat += vertex.lat;
    sumLng += vertex.lng;
  });

  return { lat: sumLat / vertices.length, lng: sumLng / vertices.length };
}
