import Button from "@mui/material/Button";
import { useState } from "react";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function OutlinedDownloadButton({ onClick, grow = false }) {
  const color = "rgb(241,93,34)";
  const [loading, setLoading] = useState(false);

  async function handleClick() {
    setLoading(true);
    await onClick();
    setLoading(false);
  }
  if (loading) {
    return (
      <Button
        variant="outlined"
        color="mainOrange"
        disabled={true}
        style={{
          borderColor: color,
          textTransform: "none",
          fontSize: "18px",
          fontWeight: "400",
          padding: "16px 32px",
          width: "100%",
        }}
        className="px-4  py-[6px]  rounded-sm"
      >
        <CircularProgress color="mainOrange" />
      </Button>
    );
  }

  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      color="mainOrange"
      style={{
        borderColor: color,
        textTransform: "none",
        fontSize: "18px",
        fontWeight: "400",
        padding: "16px 32px",
        width: "100%",
      }}
      className="px-4  py-[6px]  rounded-sm"
      endIcon={<GetAppOutlinedIcon />}
    >
      Download PDF Report
    </Button>
  );
}
