import Page from "../Components/Page";
import Stepper from "../Components/Stepper";

// CROP YEAR FORMS
import RotationForm from "../Components/CropYearForms/RotationForm";
import FieldOperationsForm from "../Components/CropYearForms/FieldOperationsForm";
import HarvestForm from "../Components/CropYearForms/HarvestForm";
import ReviewForm from "../Components/CropYearForms/ReviewForm";
import { useParams } from "react-router-dom";
import { useEffect, useContext } from "react";
import { useAuth } from "../Components/Auth/useAuth";
import { CropYearContext, FarmContext, FieldContext } from "../App";

import {
  GetSetFarm,
  GetSetField,
  GetSetCropYear,
} from "../Components/axiosFetchers";

const steps = [
  { label: "Rotation", formComponent: RotationForm },
  { label: "Field Operations", formComponent: FieldOperationsForm },
  { label: "Harvest", formComponent: HarvestForm },
];

export default function EditCropYear({}) {
  const farmContext = useContext(FarmContext);
  const fieldContext = useContext(FieldContext);
  const cropyearContext = useContext(CropYearContext);
  const { farmId, fieldId, cropyearId } = useParams();
  const { user } = useAuth();

  document.title = "Edit Crop Year Page - Field To Market Canada";

  useEffect(() => {
    async function startup() {
      await GetSetFarm(farmId, user, farmContext);
      await GetSetField(farmId, fieldId, user, fieldContext);
      await GetSetCropYear(farmId, fieldId, cropyearId, user, cropyearContext);
    }
    startup();
  }, [farmId, fieldId]);
  return (
    <Page
      showQuickFacts={true}
      title={"New Crop Year"}
      headerBorderColor={"border-[#34a853]"}
      padding="p-[0px]"
    >
      <div className="h-full w-full ">
        {/* <QuickFacts sectionColor={"#34a853"} /> */}

        <Stepper steps={steps} isEdit={true} cropyearId={cropyearId} />
      </div>
    </Page>
  );
}
