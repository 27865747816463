import AB from "./AB.json";
import ON from "./ON.json";
import MB from "./MB.json";
import QC from "./QC.json";
import SK from "./SK.json";

const MapCoordinates = {
  AB: AB,
  MB: MB,
  ON: ON,
  QC: QC,
  SK: SK,
};

export default MapCoordinates;
