import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function parseData(data) {
  const formatted = [];
  for (var observation of data) {
    let tmp = {};

    tmp["name"] = observation[0];
    tmp["Provincial Averages"] = observation[1];
    if (observation.length > 2 && observation[2].trim()) {
      tmp["This Field"] = observation[2];
    }
    formatted.push(tmp);
  }

  return formatted;
}
export default class Chart extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width={this.props.width} height={this.props.height}>
        <BarChart
          data={parseData(this.props.data)}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            name="Provincial Averages"
            dataKey="Provincial Averages"
            fill="rgb(0,164,229)"
            activeBar={<Rectangle stroke="rgb(42 195 255)" strokeWidth={3} />}
          />
          <Bar
            name="This Field"
            dataKey="This Field"
            fill="rgb(250,164,58)"
            activeBar={<Rectangle stroke="rgb(255,125,50)" strokeWidth={3} />}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
