import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, field, province, difference, flag) {
  return { name, field, province, difference, flag };
}

export default function CustomizedTables({ rawData }) {
  const rows = [
    createData("Fieldwork GHG emissions", ...rawData[0]),
    createData("Non-fieldwork GHG emissions", ...rawData[1]),
    createData("N-fertilizer", ...rawData[2]),
    createData("Crop residue", ...rawData[3]),
    createData("Leaching", ...rawData[4]),
    createData("Volatilization", ...rawData[5]),
    createData("Nitrous oxide emissions", ...rawData[6]),
    createData("Total GHG emissions", ...rawData[7]),
  ];
  console.log(rows);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>CLIMATE IMPACT</StyledTableCell>
            <StyledTableCell align="right">Field</StyledTableCell>
            <StyledTableCell align="right">Province</StyledTableCell>
            <StyledTableCell align="right">Differencial</StyledTableCell>
            <StyledTableCell align="right">Flag</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.field}</StyledTableCell>
              <StyledTableCell align="right">{row.province}</StyledTableCell>
              <StyledTableCell align="right">{row.difference}</StyledTableCell>
              <StyledTableCell align="right">{row.flag}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
