import { CropYearContext, FarmContext } from "../../../App";
import { useContext, useEffect, useState } from "react";
import SelectMachineField from "./SelectMachineField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function PlantingForm({}) {
  const farmContext = useContext(FarmContext);
  const cropyearContext = useContext(CropYearContext);
  const [machineType, setMachineType] = useState("");
  const [fieldState, setFieldState] = useState({
    machineId: "",
    hoursUsed: "",
  });

  function handleRadioInputChange(event) {
    let tmp = { ...cropyearContext.state };
    tmp.fieldOperations.plantingOperation.type = event.target.value;
    cropyearContext.setter(tmp);
  }

  function handleAppliedFertilizer(event) {
    let tmp = { ...cropyearContext.state };
    tmp.fieldOperations.plantingOperation.appliedFertilizer =
      event.target.value === "true" ? true : false;
    cropyearContext.setter(tmp);
  }

  function handleMachineChange(machineId, index) {
    let cpy = { ...cropyearContext.state };

    cpy.fieldOperations.plantingOperation.machineId = machineId;

    // console.log(machineId);
    cropyearContext.setter(cpy);
  }

  function handleHoursChange(hoursUsed) {
    let cpy = { ...cropyearContext.state };

    cpy.fieldOperations.plantingOperation.hoursUsed = hoursUsed;

    cropyearContext.setter(cpy);
  }

  useEffect(() => {
    let type =
      cropyearContext.state.fieldOperations.plantingOperation.type ===
      "STANDALONE"
        ? "PLANTER"
        : "TRACTOR";

    setMachineType(type);
    setFieldState({
      machineId:
        cropyearContext.state.fieldOperations.plantingOperation.machineId,
      hoursUsed:
        cropyearContext.state.fieldOperations.plantingOperation.hoursUsed,
    });
  }, [cropyearContext.state]);

  return (
    <section className="text-[rgb(102,102,102)] pb-5 w-full mt-8 ">
      <h4 className="font-extralight text-[36px]">Planting</h4>

      <FormControl>
        <RadioGroup
          onChange={handleRadioInputChange}
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={
            cropyearContext.state.fieldOperations.plantingOperation.type
          }
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="STANDALONE"
            control={<Radio color="mainOrange" />}
            label="Planter"
          />
          <FormControlLabel
            value="ATTACHMENT"
            control={<Radio color="mainOrange" />}
            label="Tractor Attachment"
          />
        </RadioGroup>
      </FormControl>

      <SelectMachineField
        fieldLabel={"Planter Machine"}
        machineType={machineType}
        fieldState={fieldState}
        handleHoursChange={(hoursUsed) => handleHoursChange(hoursUsed)}
        handleMachineChange={(machineId) => handleMachineChange(machineId)}
      />

      {/* 3. APPLIED FERTILIZER WHILE PLANTING? */}

      <div className="w-full flex items-center mt-4"></div>
      <FormControl>
        <p>Did you apply fertilizer at the same time?</p>
        <RadioGroup
          onChange={handleAppliedFertilizer}
          row
          aria-labelledby="demo-radio-buttons-group-label"
          value={
            cropyearContext.state.fieldOperations.plantingOperation
              .appliedFertilizer
          }
          name="radio-buttons-group"
        >
          <FormControlLabel
            value={true}
            control={<Radio color="mainOrange" />}
            label="Yes"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="default" />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
    </section>
  );
}
