const Planters = [
  // John Deere Planters
  { brand: "John Deere", model: "1775NT", HP: 210, acreHour: 30 },
  { brand: "John Deere", model: "1795", HP: 220, acreHour: 32 },
  { brand: "John Deere", model: "1770NT", HP: 200, acreHour: 28 },
  { brand: "John Deere", model: "1725", HP: 180, acreHour: 26 },
  { brand: "John Deere", model: "1765", HP: 190, acreHour: 25 },
  { brand: "John Deere", model: "DB60", HP: 250, acreHour: 40 },
  { brand: "John Deere", model: "DB44", HP: 230, acreHour: 36 },
  { brand: "John Deere", model: "DB90", HP: 300, acreHour: 45 },
  { brand: "John Deere", model: "DB120", HP: 400, acreHour: 60 },
  { brand: "John Deere", model: "1705", HP: 160, acreHour: 24 },

  // Case IH Planters
  { brand: "Case IH", model: "2140 Early Riser", HP: 180, acreHour: 25 },
  { brand: "Case IH", model: "2160 Early Riser", HP: 230, acreHour: 35 },
  { brand: "Case IH", model: "2130 Early Riser", HP: 150, acreHour: 20 },
  { brand: "Case IH", model: "2150 Early Riser", HP: 220, acreHour: 30 },
  { brand: "Case IH", model: "2100 Early Riser", HP: 200, acreHour: 28 },
  { brand: "Case IH", model: "2120 Early Riser", HP: 170, acreHour: 24 },
  { brand: "Case IH", model: "1225 Trailing", HP: 190, acreHour: 25 },
  { brand: "Case IH", model: "1235 Stack-Fold", HP: 210, acreHour: 30 },
  { brand: "Case IH", model: "1245 Pivot Transport", HP: 250, acreHour: 34 },
  { brand: "Case IH", model: "1255 Front-Fold", HP: 220, acreHour: 32 },

  // Kinze Planters
  { brand: "Kinze", model: "3605", HP: 200, acreHour: 28 },
  { brand: "Kinze", model: "3665", HP: 230, acreHour: 32 },
  { brand: "Kinze", model: "3700", HP: 250, acreHour: 38 },
  { brand: "Kinze", model: "4905", HP: 260, acreHour: 36 },
  { brand: "Kinze", model: "4705", HP: 280, acreHour: 40 },
  { brand: "Kinze", model: "3650", HP: 210, acreHour: 30 },
  { brand: "Kinze", model: "3500", HP: 180, acreHour: 24 },
  { brand: "Kinze", model: "3200", HP: 160, acreHour: 22 },
  { brand: "Kinze", model: "3600", HP: 190, acreHour: 26 },
  { brand: "Kinze", model: "4900", HP: 270, acreHour: 39 },

  // AGCO Planters
  {
    brand: "AGCO",
    model: "White Planters 9800VE Series",
    HP: 220,
    acreHour: 32,
  },
  { brand: "AGCO", model: "White Planters 9700", HP: 210, acreHour: 28 },
  { brand: "AGCO", model: "White Planters 9500", HP: 200, acreHour: 26 },
  { brand: "AGCO", model: "White Planters 9222", HP: 180, acreHour: 24 },
  { brand: "AGCO", model: "White Planters 8824", HP: 230, acreHour: 30 },
  { brand: "AGCO", model: "White Planters 8524", HP: 190, acreHour: 25 },
  { brand: "AGCO", model: "White Planters 8822", HP: 200, acreHour: 27 },
  { brand: "AGCO", model: "White Planters 8222", HP: 170, acreHour: 22 },
  { brand: "AGCO", model: "White Planters 8816", HP: 220, acreHour: 29 },
  { brand: "AGCO", model: "White Planters 8516", HP: 180, acreHour: 23 },

  // Great Plains Planters
  { brand: "Great Plains", model: "Yield-Pro Planters", HP: 200, acreHour: 30 },
  { brand: "Great Plains", model: "YP-1625A", HP: 220, acreHour: 32 },
  { brand: "Great Plains", model: "YP-2425A", HP: 240, acreHour: 35 },
  { brand: "Great Plains", model: "YP-3025A", HP: 260, acreHour: 38 },
  { brand: "Great Plains", model: "YP-1625", HP: 210, acreHour: 28 },
  { brand: "Great Plains", model: "YP-2425", HP: 230, acreHour: 33 },
  { brand: "Great Plains", model: "YP-3010", HP: 200, acreHour: 26 },
  { brand: "Great Plains", model: "YP-4025A", HP: 280, acreHour: 42 },
  { brand: "Great Plains", model: "YP-1620F", HP: 180, acreHour: 24 },
  { brand: "Great Plains", model: "YP-4025F", HP: 250, acreHour: 36 },

  // Monosem Planters
  { brand: "Monosem", model: "NG Plus 4", HP: 190, acreHour: 26 },
  { brand: "Monosem", model: "NX M", HP: 180, acreHour: 25 },
  { brand: "Monosem", model: "NG Plus 3", HP: 170, acreHour: 22 },
  { brand: "Monosem", model: "NC Classic", HP: 160, acreHour: 21 },
  { brand: "Monosem", model: "NG Plus 2", HP: 150, acreHour: 20 },
  { brand: "Monosem", model: "NG Plus 1", HP: 140, acreHour: 18 },
  { brand: "Monosem", model: "NC Classic M", HP: 155, acreHour: 19 },
  { brand: "Monosem", model: "MS", HP: 165, acreHour: 23 },
  { brand: "Monosem", model: "NG Plus 4M", HP: 200, acreHour: 28 },
  { brand: "Monosem", model: "NX ME", HP: 175, acreHour: 24 },

  // Horsch Planters
  { brand: "Horsch", model: "Maestro SV", HP: 210, acreHour: 35 },
  { brand: "Horsch", model: "Maestro RV", HP: 230, acreHour: 38 },
  { brand: "Horsch", model: "Maestro SW", HP: 220, acreHour: 36 },
  { brand: "Horsch", model: "Maestro CV", HP: 200, acreHour: 30 },
  { brand: "Horsch", model: "Pronto DC", HP: 240, acreHour: 37 },
  { brand: "Horsch", model: "Pronto NT", HP: 250, acreHour: 39 },
  { brand: "Horsch", model: "Avatar SD", HP: 270, acreHour: 42 },
  { brand: "Horsch", model: "Express KR", HP: 180, acreHour: 25 },
  { brand: "Horsch", model: "Sprinter NT", HP: 260, acreHour: 40 },
  { brand: "Horsch", model: "Serto SC", HP: 300, acreHour: 45 },

  // Väderstad Planters
  { brand: "Väderstad", model: "Tempo L", HP: 230, acreHour: 36 },
  { brand: "Väderstad", model: "Tempo F", HP: 220, acreHour: 34 },
  { brand: "Väderstad", model: "Tempo T", HP: 210, acreHour: 32 },
  { brand: "Väderstad", model: "Tempo R", HP: 200, acreHour: 30 },
  { brand: "Väderstad", model: "Tempo V", HP: 240, acreHour: 37 },
  { brand: "Väderstad", model: "Tempo S", HP: 260, acreHour: 40 },
  { brand: "Väderstad", model: "Spirit R", HP: 250, acreHour: 38 },
  { brand: "Väderstad", model: "Spirit ST", HP: 270, acreHour: 42 },
  { brand: "Väderstad", model: "Carrier", HP: 230, acreHour: 33 },
  { brand: "Väderstad", model: "Rapid A", HP: 300, acreHour: 45 },

  // Bourgault Planters
  {
    brand: "Bourgault",
    model: "3320 PHD Paralink Hoe Drill",
    HP: 300,
    acreHour: 40,
  },
  {
    brand: "Bourgault",
    model: "3710 ICD Independent Coulter Drill",
    HP: 280,
    acreHour: 39,
  },
  {
    brand: "Bourgault",
    model: "3320 QDA Quick Depth Adjust",
    HP: 270,
    acreHour: 38,
  },
  { brand: "Bourgault", model: "3320 XTC", HP: 290, acreHour: 42 },
  { brand: "Bourgault", model: "3720 ICD", HP: 320, acreHour: 46 },
  { brand: "Bourgault", model: "5810 Air Hoe Drill", HP: 310, acreHour: 43 },
  { brand: "Bourgault", model: "5810 ACT", HP: 350, acreHour: 50 },
  {
    brand: "Bourgault",
    model: "3420 PHD Paralink Hoe Drill",
    HP: 320,
    acreHour: 45,
  },
  { brand: "Bourgault", model: "3320 CCT", HP: 280, acreHour: 37 },
  { brand: "Bourgault", model: "3720 CCT", HP: 340, acreHour: 47 },

  // SeedMaster Planters
  { brand: "SeedMaster", model: "Ultra SR", HP: 250, acreHour: 38 },
  { brand: "SeedMaster", model: "Ultra Pro II", HP: 270, acreHour: 40 },
  { brand: "SeedMaster", model: "Ultra Pro III", HP: 290, acreHour: 42 },
  { brand: "SeedMaster", model: "Ultra Pro IV", HP: 310, acreHour: 44 },
  { brand: "SeedMaster", model: "Ultra SR Plus", HP: 260, acreHour: 39 },
  { brand: "SeedMaster", model: "Ultra Pro V", HP: 330, acreHour: 46 },
  { brand: "SeedMaster", model: "Ultra Pro VI", HP: 350, acreHour: 48 },
  { brand: "SeedMaster", model: "Ultra Pro VII", HP: 370, acreHour: 50 },
  { brand: "SeedMaster", model: "Ultra SR Flex", HP: 280, acreHour: 41 },
  { brand: "SeedMaster", model: "Ultra Pro Flex", HP: 360, acreHour: 49 },
];

const Tractors = [
  // John Deere Tractors
  { brand: "John Deere", model: "8R 370", HP: 370, acreHour: 35 },
  { brand: "John Deere", model: "7R 290", HP: 290, acreHour: 32 },
  { brand: "John Deere", model: "6M 185", HP: 185, acreHour: 28 },
  { brand: "John Deere", model: "5E 105", HP: 105, acreHour: 22 },
  { brand: "John Deere", model: "9R 540", HP: 540, acreHour: 50 },
  { brand: "John Deere", model: "8RT 410", HP: 410, acreHour: 45 },
  { brand: "John Deere", model: "6R 155", HP: 155, acreHour: 25 },
  { brand: "John Deere", model: "5100M", HP: 100, acreHour: 20 },
  { brand: "John Deere", model: "4066R", HP: 66, acreHour: 15 },
  { brand: "John Deere", model: "3033R", HP: 33, acreHour: 10 },

  // Case IH Tractors
  { brand: "Case IH", model: "Magnum 400", HP: 400, acreHour: 40 },
  { brand: "Case IH", model: "Puma 240", HP: 240, acreHour: 30 },
  { brand: "Case IH", model: "Farmall 140A", HP: 140, acreHour: 22 },
  { brand: "Case IH", model: "Maxxum 150", HP: 150, acreHour: 25 },
  { brand: "Case IH", model: "Steiger 620", HP: 620, acreHour: 55 },
  { brand: "Case IH", model: "Quadtrac 500", HP: 500, acreHour: 48 },
  { brand: "Case IH", model: "Optum 300", HP: 300, acreHour: 38 },
  { brand: "Case IH", model: "Farmall 100C", HP: 100, acreHour: 18 },
  { brand: "Case IH", model: "Puma 165", HP: 165, acreHour: 27 },
  { brand: "Case IH", model: "Maxxum 125", HP: 125, acreHour: 20 },

  // New Holland Tractors
  { brand: "New Holland", model: "T9.700", HP: 700, acreHour: 58 },
  { brand: "New Holland", model: "T8.435", HP: 435, acreHour: 47 },
  { brand: "New Holland", model: "T7.315", HP: 315, acreHour: 36 },
  { brand: "New Holland", model: "T6.180", HP: 180, acreHour: 28 },
  { brand: "New Holland", model: "T4.120", HP: 120, acreHour: 20 },
  { brand: "New Holland", model: "T5.140", HP: 140, acreHour: 23 },
  { brand: "New Holland", model: "T7.270", HP: 270, acreHour: 35 },
  { brand: "New Holland", model: "T6.150", HP: 150, acreHour: 24 },
  { brand: "New Holland", model: "T4.100", HP: 100, acreHour: 18 },
  { brand: "New Holland", model: "T8.350", HP: 350, acreHour: 40 },

  // Kubota Tractors
  { brand: "Kubota", model: "M8-211", HP: 211, acreHour: 30 },
  { brand: "Kubota", model: "M7-172", HP: 172, acreHour: 25 },
  { brand: "Kubota", model: "M6-141", HP: 141, acreHour: 22 },
  { brand: "Kubota", model: "L6060", HP: 62, acreHour: 12 },
  { brand: "Kubota", model: "M5-111", HP: 111, acreHour: 18 },
  { brand: "Kubota", model: "M135GX", HP: 135, acreHour: 22 },
  { brand: "Kubota", model: "BX2380", HP: 23, acreHour: 8 },
  { brand: "Kubota", model: "M7060", HP: 71, acreHour: 15 },
  { brand: "Kubota", model: "L2501", HP: 24, acreHour: 9 },
  { brand: "Kubota", model: "MX5800", HP: 61, acreHour: 14 },

  // Massey Ferguson Tractors
  { brand: "Massey Ferguson", model: "8737S", HP: 370, acreHour: 40 },
  { brand: "Massey Ferguson", model: "7720S", HP: 200, acreHour: 28 },
  { brand: "Massey Ferguson", model: "5711S", HP: 115, acreHour: 22 },
  { brand: "Massey Ferguson", model: "4708", HP: 80, acreHour: 16 },
  { brand: "Massey Ferguson", model: "8690", HP: 370, acreHour: 43 },
  { brand: "Massey Ferguson", model: "7618", HP: 180, acreHour: 30 },
  { brand: "Massey Ferguson", model: "5713S", HP: 130, acreHour: 23 },
  { brand: "Massey Ferguson", model: "4610", HP: 100, acreHour: 18 },
  { brand: "Massey Ferguson", model: "5455", HP: 105, acreHour: 20 },
  { brand: "Massey Ferguson", model: "7616", HP: 160, acreHour: 26 },

  // Fendt Tractors
  { brand: "Fendt", model: "1050 Vario", HP: 517, acreHour: 52 },
  { brand: "Fendt", model: "933 Vario", HP: 326, acreHour: 40 },
  { brand: "Fendt", model: "724 Vario", HP: 246, acreHour: 35 },
  { brand: "Fendt", model: "516 Vario", HP: 163, acreHour: 28 },
  { brand: "Fendt", model: "1038 Vario", HP: 396, acreHour: 50 },
  { brand: "Fendt", model: "930 Vario", HP: 305, acreHour: 38 },
  { brand: "Fendt", model: "720 Vario", HP: 205, acreHour: 32 },
  { brand: "Fendt", model: "312 Vario", HP: 125, acreHour: 20 },
  { brand: "Fendt", model: "211 Vario", HP: 110, acreHour: 18 },
  { brand: "Fendt", model: "516 Vario", HP: 165, acreHour: 28 },

  // Deutz-Fahr Tractors
  { brand: "Deutz-Fahr", model: "9340 TTV", HP: 340, acreHour: 45 },
  { brand: "Deutz-Fahr", model: "7250 TTV", HP: 250, acreHour: 35 },
  { brand: "Deutz-Fahr", model: "6185 TTV", HP: 185, acreHour: 30 },
  { brand: "Deutz-Fahr", model: "5110 G", HP: 110, acreHour: 22 },
  { brand: "Deutz-Fahr", model: "9340 TTV", HP: 340, acreHour: 44 },
  { brand: "Deutz-Fahr", model: "7250 TTV", HP: 250, acreHour: 36 },
  { brand: "Deutz-Fahr", model: "5120 C", HP: 120, acreHour: 24 },
  { brand: "Deutz-Fahr", model: "4080 E", HP: 80, acreHour: 16 },
  { brand: "Deutz-Fahr", model: "5125 G", HP: 125, acreHour: 25 },
  { brand: "Deutz-Fahr", model: "9340 TTV", HP: 340, acreHour: 45 },

  // Claas Tractors
  { brand: "Claas", model: "Axion 960", HP: 445, acreHour: 50 },
  { brand: "Claas", model: "Axion 850", HP: 295, acreHour: 38 },
  { brand: "Claas", model: "Arion 660", HP: 205, acreHour: 32 },
  { brand: "Claas", model: "Arion 420", HP: 120, acreHour: 20 },
  { brand: "Claas", model: "Xerion 5000", HP: 500, acreHour: 55 },
  { brand: "Claas", model: "Axion 810", HP: 280, acreHour: 37 },
  { brand: "Claas", model: "Arion 650", HP: 185, acreHour: 29 },
  { brand: "Claas", model: "Arion 510", HP: 125, acreHour: 22 },
  { brand: "Claas", model: "Xerion 4000", HP: 435, acreHour: 48 },
  { brand: "Claas", model: "Axion 830", HP: 260, acreHour: 35 },

  // Valtra Tractors
  { brand: "Valtra", model: "T234", HP: 250, acreHour: 36 },
  { brand: "Valtra", model: "N174", HP: 175, acreHour: 28 },
  { brand: "Valtra", model: "A114", HP: 110, acreHour: 20 },
  { brand: "Valtra", model: "T254", HP: 271, acreHour: 39 },
  { brand: "Valtra", model: "N154", HP: 165, acreHour: 27 },
  { brand: "Valtra", model: "A134", HP: 130, acreHour: 22 },
  { brand: "Valtra", model: "T214", HP: 230, acreHour: 34 },
  { brand: "Valtra", model: "N134", HP: 145, acreHour: 24 },
  { brand: "Valtra", model: "A94", HP: 100, acreHour: 18 },
  { brand: "Valtra", model: "T174", HP: 190, acreHour: 30 },

  // Challenger Tractors
  { brand: "Challenger", model: "MT875E", HP: 590, acreHour: 60 },
  { brand: "Challenger", model: "MT765D", HP: 355, acreHour: 45 },
  { brand: "Challenger", model: "MT685D", HP: 310, acreHour: 38 },
  { brand: "Challenger", model: "MT865E", HP: 540, acreHour: 55 },
  { brand: "Challenger", model: "MT775E", HP: 430, acreHour: 50 },
  { brand: "Challenger", model: "MT745C", HP: 305, acreHour: 35 },
  { brand: "Challenger", model: "MT845E", HP: 510, acreHour: 52 },
  { brand: "Challenger", model: "MT755D", HP: 340, acreHour: 42 },
  { brand: "Challenger", model: "MT845C", HP: 510, acreHour: 51 },
  { brand: "Challenger", model: "MT865C", HP: 540, acreHour: 54 },

  // JCB Tractors
  { brand: "JCB", model: "Fastrac 8330", HP: 348, acreHour: 43 },
  { brand: "JCB", model: "Fastrac 4220", HP: 235, acreHour: 32 },
  { brand: "JCB", model: "Fastrac 3230", HP: 220, acreHour: 30 },
  { brand: "JCB", model: "Fastrac 2155", HP: 160, acreHour: 25 },
  { brand: "JCB", model: "Fastrac 4160", HP: 160, acreHour: 24 },
  { brand: "JCB", model: "Fastrac 7180", HP: 178, acreHour: 26 },
  { brand: "JCB", model: "Fastrac 4220 iCON", HP: 220, acreHour: 30 },
  { brand: "JCB", model: "Fastrac 3170", HP: 175, acreHour: 26 },
  { brand: "JCB", model: "Fastrac 8250", HP: 250, acreHour: 34 },
  { brand: "JCB", model: "Fastrac 7230", HP: 230, acreHour: 33 },

  // Zetor Tractors
  { brand: "Zetor", model: "Forterra 150 HD", HP: 150, acreHour: 24 },
  { brand: "Zetor", model: "Proxima 120", HP: 117, acreHour: 22 },
  { brand: "Zetor", model: "Major CL 80", HP: 75, acreHour: 16 },
  { brand: "Zetor", model: "Crystal HD 170", HP: 171, acreHour: 27 },
  { brand: "Zetor", model: "Proxima CL 110", HP: 106, acreHour: 21 },
  { brand: "Zetor", model: "Forterra CL 140", HP: 136, acreHour: 23 },
  { brand: "Zetor", model: "Major CL 80", HP: 75, acreHour: 16 },
  { brand: "Zetor", model: "Proxima GP 110", HP: 106, acreHour: 20 },
  { brand: "Zetor", model: "Forterra HSX 140", HP: 136, acreHour: 23 },
  { brand: "Zetor", model: "Proxima Power 120", HP: 117, acreHour: 22 },

  // Same Tractors
  { brand: "Same", model: "Virtus 120", HP: 120, acreHour: 23 },
  { brand: "Same", model: "Explorer 110", HP: 110, acreHour: 20 },
  { brand: "Same", model: "Dorado 90", HP: 90, acreHour: 18 },
  { brand: "Same", model: "Virtus 140", HP: 140, acreHour: 25 },
  { brand: "Same", model: "Virtus 120 DT", HP: 120, acreHour: 23 },
  { brand: "Same", model: "Explorer 100", HP: 100, acreHour: 19 },
  { brand: "Same", model: "Virtus 130", HP: 130, acreHour: 24 },
  { brand: "Same", model: "Silver 130", HP: 130, acreHour: 24 },
  { brand: "Same", model: "Explorer 110 DT", HP: 110, acreHour: 20 },
  { brand: "Same", model: "Dorado 100", HP: 100, acreHour: 19 },
];

const Sprayers = [
  // John Deere Sprayers
  { brand: "John Deere", model: "R4045", HP: 346, acreHour: 100 },
  { brand: "John Deere", model: "R4038", HP: 310, acreHour: 90 },
  { brand: "John Deere", model: "R4030", HP: 280, acreHour: 80 },
  { brand: "John Deere", model: "4830", HP: 275, acreHour: 75 },
  { brand: "John Deere", model: "4940", HP: 340, acreHour: 105 },
  { brand: "John Deere", model: "4730", HP: 245, acreHour: 70 },
  { brand: "John Deere", model: "4630", HP: 225, acreHour: 65 },
  { brand: "John Deere", model: "4630", HP: 225, acreHour: 60 },
  { brand: "John Deere", model: "4930", HP: 325, acreHour: 95 },
  { brand: "John Deere", model: "4630", HP: 225, acreHour: 65 },

  // Case IH Sprayers
  { brand: "Case IH", model: "Patriot 4440", HP: 335, acreHour: 100 },
  { brand: "Case IH", model: "Patriot 3340", HP: 285, acreHour: 85 },
  { brand: "Case IH", model: "Patriot 3240", HP: 250, acreHour: 80 },
  { brand: "Case IH", model: "Patriot 3230", HP: 225, acreHour: 75 },
  { brand: "Case IH", model: "Patriot 4430", HP: 310, acreHour: 95 },
  { brand: "Case IH", model: "Patriot 3330", HP: 265, acreHour: 85 },
  { brand: "Case IH", model: "Patriot 3230", HP: 225, acreHour: 70 },
  { brand: "Case IH", model: "Patriot 3320", HP: 240, acreHour: 78 },
  { brand: "Case IH", model: "Patriot 4420", HP: 300, acreHour: 92 },
  { brand: "Case IH", model: "Patriot 3320", HP: 240, acreHour: 77 },

  // New Holland Sprayers
  { brand: "New Holland", model: "SP400F", HP: 400, acreHour: 105 },
  { brand: "New Holland", model: "SP300F", HP: 300, acreHour: 95 },
  { brand: "New Holland", model: "Guardian SP310F", HP: 310, acreHour: 97 },
  { brand: "New Holland", model: "SP275F", HP: 275, acreHour: 90 },
  { brand: "New Holland", model: "SP365F", HP: 365, acreHour: 100 },
  { brand: "New Holland", model: "Guardian SP240F", HP: 240, acreHour: 85 },
  { brand: "New Holland", model: "SP275F", HP: 275, acreHour: 88 },
  { brand: "New Holland", model: "Guardian SP310F", HP: 310, acreHour: 95 },
  { brand: "New Holland", model: "SP365F", HP: 365, acreHour: 102 },
  { brand: "New Holland", model: "SP240F", HP: 240, acreHour: 80 },

  // Hagie Sprayers
  { brand: "Hagie", model: "STS16", HP: 400, acreHour: 110 },
  { brand: "Hagie", model: "STS12", HP: 300, acreHour: 95 },
  { brand: "Hagie", model: "STS10", HP: 300, acreHour: 90 },
  { brand: "Hagie", model: "STS14", HP: 365, acreHour: 100 },
  { brand: "Hagie", model: "STS14", HP: 365, acreHour: 98 },
  { brand: "Hagie", model: "STS10", HP: 300, acreHour: 88 },
  { brand: "Hagie", model: "STS16", HP: 400, acreHour: 110 },
  { brand: "Hagie", model: "STS14", HP: 365, acreHour: 100 },
  { brand: "Hagie", model: "STS12", HP: 300, acreHour: 95 },
  { brand: "Hagie", model: "STS10", HP: 300, acreHour: 85 },

  // Apache Sprayers
  { brand: "Apache", model: "AS1220", HP: 300, acreHour: 90 },
  { brand: "Apache", model: "AS1020", HP: 275, acreHour: 85 },
  { brand: "Apache", model: "AS1220", HP: 300, acreHour: 92 },
  { brand: "Apache", model: "AS1020", HP: 275, acreHour: 88 },
  { brand: "Apache", model: "AS720", HP: 215, acreHour: 70 },
  { brand: "Apache", model: "AS1220", HP: 300, acreHour: 90 },
  { brand: "Apache", model: "AS1020", HP: 275, acreHour: 85 },
  { brand: "Apache", model: "AS720", HP: 215, acreHour: 75 },
  { brand: "Apache", model: "AS920", HP: 240, acreHour: 78 },
  { brand: "Apache", model: "AS720", HP: 215, acreHour: 72 },

  // Miller Sprayers
  { brand: "Miller", model: "Nitro 7310", HP: 310, acreHour: 95 },
  { brand: "Miller", model: "Nitro 6365", HP: 365, acreHour: 100 },
  { brand: "Miller", model: "Nitro 7310", HP: 310, acreHour: 95 },
  { brand: "Miller", model: "Nitro 6365", HP: 365, acreHour: 100 },
  { brand: "Miller", model: "Nitro 6365", HP: 365, acreHour: 105 },
  { brand: "Miller", model: "Nitro 5315", HP: 275, acreHour: 90 },
  { brand: "Miller", model: "Nitro 6365", HP: 365, acreHour: 100 },
  { brand: "Miller", model: "Nitro 7310", HP: 310, acreHour: 95 },
  { brand: "Miller", model: "Nitro 5315", HP: 275, acreHour: 88 },
  { brand: "Miller", model: "Nitro 4240", HP: 240, acreHour: 85 },

  // Ag-Chem Sprayers
  { brand: "Ag-Chem", model: "RoGator 1300C", HP: 339, acreHour: 100 },
  { brand: "Ag-Chem", model: "RoGator 1100C", HP: 310, acreHour: 90 },
  { brand: "Ag-Chem", model: "RoGator 1300B", HP: 339, acreHour: 100 },
  { brand: "Ag-Chem", model: "RoGator 1100C", HP: 310, acreHour: 90 },
  { brand: "Ag-Chem", model: "RoGator 1300", HP: 339, acreHour: 98 },
  { brand: "Ag-Chem", model: "RoGator 1100B", HP: 310, acreHour: 90 },
  { brand: "Ag-Chem", model: "RoGator 1300C", HP: 339, acreHour: 102 },
  { brand: "Ag-Chem", model: "RoGator 1100B", HP: 310, acreHour: 90 },
  { brand: "Ag-Chem", model: "RoGator 1300", HP: 339, acreHour: 98 },
  { brand: "Ag-Chem", model: "RoGator 1100B", HP: 310, acreHour: 90 },

  // Hardi Sprayers
  { brand: "Hardi", model: "Commander 4500", HP: 255, acreHour: 75 },
  { brand: "Hardi", model: "Navigator 6000", HP: 275, acreHour: 80 },
  { brand: "Hardi", model: "Alpha Evo 4100", HP: 280, acreHour: 82 },
  { brand: "Hardi", model: "Alpha Evo 5100", HP: 310, acreHour: 90 },
  { brand: "Hardi", model: "Rubicon 9000", HP: 380, acreHour: 105 },
  { brand: "Hardi", model: "Navigator 4500", HP: 245, acreHour: 75 },
  { brand: "Hardi", model: "Commander 7000", HP: 310, acreHour: 95 },
  { brand: "Hardi", model: "Alpha Evo 4100", HP: 280, acreHour: 82 },
  { brand: "Hardi", model: "Alpha Evo 5100", HP: 310, acreHour: 90 },
  { brand: "Hardi", model: "Rubicon 9000", HP: 380, acreHour: 105 },

  // Kuhn Sprayers
  { brand: "Kuhn", model: "Metris 4102", HP: 240, acreHour: 75 },
  { brand: "Kuhn", model: "Metris 3202", HP: 220, acreHour: 70 },
  { brand: "Kuhn", model: "Metris 4102", HP: 240, acreHour: 75 },
  { brand: "Kuhn", model: "Metris 3202", HP: 220, acreHour: 70 },
  { brand: "Kuhn", model: "Metris 6102", HP: 280, acreHour: 85 },
  { brand: "Kuhn", model: "Metris 4102", HP: 240, acreHour: 75 },
  { brand: "Kuhn", model: "Metris 4102", HP: 240, acreHour: 75 },
  { brand: "Kuhn", model: "Metris 3202", HP: 220, acreHour: 70 },
  { brand: "Kuhn", model: "Metris 6102", HP: 280, acreHour: 85 },
  { brand: "Kuhn", model: "Metris 4102", HP: 240, acreHour: 75 },

  // Amazone Sprayers
  { brand: "Amazone", model: "Pantera 4502", HP: 218, acreHour: 75 },
  { brand: "Amazone", model: "Pantera 4503", HP: 225, acreHour: 78 },
  { brand: "Amazone", model: "Pantera 4001", HP: 200, acreHour: 70 },
  { brand: "Amazone", model: "UX 5200", HP: 195, acreHour: 65 },
  { brand: "Amazone", model: "UX 11200", HP: 310, acreHour: 85 },
  { brand: "Amazone", model: "Pantera 4502", HP: 218, acreHour: 75 },
  { brand: "Amazone", model: "Pantera 4503", HP: 225, acreHour: 78 },
  { brand: "Amazone", model: "Pantera 4001", HP: 200, acreHour: 70 },
  { brand: "Amazone", model: "UX 5200", HP: 195, acreHour: 65 },
  { brand: "Amazone", model: "UX 11200", HP: 310, acreHour: 85 },

  // GVM Sprayers
  { brand: "GVM", model: "Prowler 9275", HP: 375, acreHour: 105 },
  { brand: "GVM", model: "Prowler 1135", HP: 400, acreHour: 110 },
  { brand: "GVM", model: "Prowler 1230", HP: 425, acreHour: 115 },
  { brand: "GVM", model: "Prowler 9275", HP: 375, acreHour: 105 },
  { brand: "GVM", model: "Prowler 1135", HP: 400, acreHour: 110 },
  { brand: "GVM", model: "Prowler 1230", HP: 425, acreHour: 115 },
  { brand: "GVM", model: "Prowler 1230", HP: 425, acreHour: 115 },
  { brand: "GVM", model: "Prowler 1135", HP: 400, acreHour: 110 },
  { brand: "GVM", model: "Prowler 9275", HP: 375, acreHour: 105 },
  { brand: "GVM", model: "Prowler 1135", HP: 400, acreHour: 110 },

  // Agrifac Sprayers
  { brand: "Agrifac", model: "Condor Endurance", HP: 430, acreHour: 115 },
  { brand: "Agrifac", model: "Condor IV", HP: 300, acreHour: 95 },
  { brand: "Agrifac", model: "Condor V", HP: 350, acreHour: 100 },
  { brand: "Agrifac", model: "Condor Endurance", HP: 430, acreHour: 115 },
  { brand: "Agrifac", model: "Condor IV", HP: 300, acreHour: 95 },
  { brand: "Agrifac", model: "Condor V", HP: 350, acreHour: 100 },
  { brand: "Agrifac", model: "Condor Endurance", HP: 430, acreHour: 115 },
  { brand: "Agrifac", model: "Condor IV", HP: 300, acreHour: 95 },
  { brand: "Agrifac", model: "Condor V", HP: 350, acreHour: 100 },
  { brand: "Agrifac", model: "Condor Endurance", HP: 430, acreHour: 115 },
];

const Combines = [
  // John Deere Combines
  { brand: "John Deere", model: "S790", HP: 543, acreHour: 150 },
  { brand: "John Deere", model: "S770", HP: 473, acreHour: 135 },
  { brand: "John Deere", model: "S780", HP: 503, acreHour: 140 },
  { brand: "John Deere", model: "S760", HP: 375, acreHour: 120 },
  { brand: "John Deere", model: "S690", HP: 530, acreHour: 145 },
  { brand: "John Deere", model: "S660", HP: 360, acreHour: 115 },
  { brand: "John Deere", model: "S670", HP: 440, acreHour: 130 },
  { brand: "John Deere", model: "S680", HP: 473, acreHour: 140 },
  { brand: "John Deere", model: "S685", HP: 500, acreHour: 143 },
  { brand: "John Deere", model: "S640", HP: 320, acreHour: 110 },

  // Case IH Combines
  { brand: "Case IH", model: "Axial-Flow 8250", HP: 480, acreHour: 145 },
  { brand: "Case IH", model: "Axial-Flow 7150", HP: 375, acreHour: 120 },
  { brand: "Case IH", model: "Axial-Flow 9250", HP: 550, acreHour: 155 },
  { brand: "Case IH", model: "Axial-Flow 6150", HP: 340, acreHour: 110 },
  { brand: "Case IH", model: "Axial-Flow 7250", HP: 450, acreHour: 140 },
  { brand: "Case IH", model: "Axial-Flow 9250", HP: 550, acreHour: 155 },
  { brand: "Case IH", model: "Axial-Flow 8240", HP: 480, acreHour: 145 },
  { brand: "Case IH", model: "Axial-Flow 7230", HP: 450, acreHour: 140 },
  { brand: "Case IH", model: "Axial-Flow 8240", HP: 480, acreHour: 145 },
  { brand: "Case IH", model: "Axial-Flow 7230", HP: 450, acreHour: 140 },

  // New Holland Combines
  { brand: "New Holland", model: "CR10.90", HP: 653, acreHour: 160 },
  { brand: "New Holland", model: "CR8.90", HP: 517, acreHour: 145 },
  { brand: "New Holland", model: "CR9.90", HP: 591, acreHour: 150 },
  { brand: "New Holland", model: "CR7.90", HP: 449, acreHour: 135 },
  { brand: "New Holland", model: "CR8.80", HP: 517, acreHour: 145 },
  { brand: "New Holland", model: "CR8.90", HP: 517, acreHour: 145 },
  { brand: "New Holland", model: "CR9.90", HP: 591, acreHour: 150 },
  { brand: "New Holland", model: "CR10.90", HP: 653, acreHour: 160 },
  { brand: "New Holland", model: "CR7.90", HP: 449, acreHour: 135 },
  { brand: "New Holland", model: "CR8.90", HP: 517, acreHour: 145 },

  // Claas Combines
  { brand: "Claas", model: "LEXION 8900", HP: 790, acreHour: 170 },
  { brand: "Claas", model: "LEXION 8700", HP: 585, acreHour: 150 },
  { brand: "Claas", model: "LEXION 780", HP: 616, acreHour: 155 },
  { brand: "Claas", model: "LEXION 760", HP: 503, acreHour: 140 },
  { brand: "Claas", model: "LEXION 7500", HP: 536, acreHour: 145 },
  { brand: "Claas", model: "LEXION 7700", HP: 626, acreHour: 160 },
  { brand: "Claas", model: "LEXION 7400", HP: 503, acreHour: 140 },
  { brand: "Claas", model: "LEXION 760", HP: 503, acreHour: 140 },
  { brand: "Claas", model: "LEXION 780", HP: 616, acreHour: 155 },
  { brand: "Claas", model: "LEXION 8700", HP: 585, acreHour: 150 },

  // Massey Ferguson Combines
  { brand: "Massey Ferguson", model: "MF 9895", HP: 425, acreHour: 130 },
  { brand: "Massey Ferguson", model: "MF 9540", HP: 370, acreHour: 120 },
  { brand: "Massey Ferguson", model: "MF 9790", HP: 365, acreHour: 125 },
  { brand: "Massey Ferguson", model: "MF 9690", HP: 340, acreHour: 115 },
  { brand: "Massey Ferguson", model: "MF 9895", HP: 425, acreHour: 130 },
  { brand: "Massey Ferguson", model: "MF 9540", HP: 370, acreHour: 120 },
  { brand: "Massey Ferguson", model: "MF 9790", HP: 365, acreHour: 125 },
  { brand: "Massey Ferguson", model: "MF 9690", HP: 340, acreHour: 115 },
  { brand: "Massey Ferguson", model: "MF 9895", HP: 425, acreHour: 130 },
  { brand: "Massey Ferguson", model: "MF 9540", HP: 370, acreHour: 120 },

  // Gleaner Combines
  { brand: "Gleaner", model: "S98", HP: 471, acreHour: 140 },
  { brand: "Gleaner", model: "S97", HP: 430, acreHour: 135 },
  { brand: "Gleaner", model: "S96", HP: 375, acreHour: 130 },
  { brand: "Gleaner", model: "S77", HP: 370, acreHour: 125 },
  { brand: "Gleaner", model: "S88", HP: 430, acreHour: 135 },
  { brand: "Gleaner", model: "S98", HP: 471, acreHour: 140 },
  { brand: "Gleaner", model: "S97", HP: 430, acreHour: 135 },
  { brand: "Gleaner", model: "S96", HP: 375, acreHour: 130 },
  { brand: "Gleaner", model: "S88", HP: 430, acreHour: 135 },
  { brand: "Gleaner", model: "S77", HP: 370, acreHour: 125 },

  // Fendt Combines
  { brand: "Fendt", model: "Ideal 9T", HP: 647, acreHour: 155 },
  { brand: "Fendt", model: "Ideal 8T", HP: 538, acreHour: 145 },
  { brand: "Fendt", model: "Ideal 7T", HP: 451, acreHour: 135 },
  { brand: "Fendt", model: "5275 C", HP: 276, acreHour: 95 },
  { brand: "Fendt", model: "6335 C", HP: 354, acreHour: 115 },
  { brand: "Fendt", model: "6275 L", HP: 306, acreHour: 100 },
  { brand: "Fendt", model: "6335 C", HP: 354, acreHour: 115 },
  { brand: "Fendt", model: "6275 L", HP: 306, acreHour: 100 },
  { brand: "Fendt", model: "5275 C", HP: 276, acreHour: 95 },
  { brand: "Fendt", model: "Ideal 9T", HP: 647, acreHour: 155 },

  // Laverda Combines
  { brand: "Laverda", model: "M410", HP: 300, acreHour: 105 },
  { brand: "Laverda", model: "M400", HP: 279, acreHour: 100 },
  { brand: "Laverda", model: "M410", HP: 300, acreHour: 105 },
  { brand: "Laverda", model: "M400", HP: 279, acreHour: 100 },
  { brand: "Laverda", model: "M400 LCI", HP: 279, acreHour: 100 },
  { brand: "Laverda", model: "M410", HP: 300, acreHour: 105 },
  { brand: "Laverda", model: "M410", HP: 300, acreHour: 105 },
  { brand: "Laverda", model: "M400 LCI", HP: 279, acreHour: 100 },
  { brand: "Laverda", model: "M400", HP: 279, acreHour: 100 },
  { brand: "Laverda", model: "M410", HP: 300, acreHour: 105 },

  // Deutz-Fahr Combines
  { brand: "Deutz-Fahr", model: "C9306", HP: 366, acreHour: 120 },
  { brand: "Deutz-Fahr", model: "C9206", HP: 340, acreHour: 115 },
  { brand: "Deutz-Fahr", model: "C7206", HP: 276, acreHour: 100 },
  { brand: "Deutz-Fahr", model: "C6205", HP: 258, acreHour: 95 },
  { brand: "Deutz-Fahr", model: "C9306", HP: 366, acreHour: 120 },
  { brand: "Deutz-Fahr", model: "C9206", HP: 340, acreHour: 115 },
  { brand: "Deutz-Fahr", model: "C7206", HP: 276, acreHour: 100 },
  { brand: "Deutz-Fahr", model: "C6205", HP: 258, acreHour: 95 },
  { brand: "Deutz-Fahr", model: "C9306", HP: 366, acreHour: 120 },
  { brand: "Deutz-Fahr", model: "C9206", HP: 340, acreHour: 115 },

  // Versatile Combines
  { brand: "Versatile", model: "RT490", HP: 490, acreHour: 135 },
  { brand: "Versatile", model: "RT540", HP: 540, acreHour: 140 },
  { brand: "Versatile", model: "RT490", HP: 490, acreHour: 135 },
  { brand: "Versatile", model: "RT540", HP: 540, acreHour: 140 },
  { brand: "Versatile", model: "RT490", HP: 490, acreHour: 135 },
  { brand: "Versatile", model: "RT540", HP: 540, acreHour: 140 },
  { brand: "Versatile", model: "RT490", HP: 490, acreHour: 135 },
  { brand: "Versatile", model: "RT540", HP: 540, acreHour: 140 },
  { brand: "Versatile", model: "RT490", HP: 490, acreHour: 135 },
  { brand: "Versatile", model: "RT540", HP: 540, acreHour: 140 },

  // Sampo-Rosenlew Combines
  { brand: "Sampo-Rosenlew", model: "Comia C10", HP: 300, acreHour: 95 },
  { brand: "Sampo-Rosenlew", model: "Comia C12", HP: 350, acreHour: 100 },
  { brand: "Sampo-Rosenlew", model: "Comia C8", HP: 275, acreHour: 90 },
  { brand: "Sampo-Rosenlew", model: "Comia C10", HP: 300, acreHour: 95 },
  { brand: "Sampo-Rosenlew", model: "Comia C12", HP: 350, acreHour: 100 },
  { brand: "Sampo-Rosenlew", model: "Comia C8", HP: 275, acreHour: 90 },
  { brand: "Sampo-Rosenlew", model: "Comia C10", HP: 300, acreHour: 95 },
  { brand: "Sampo-Rosenlew", model: "Comia C12", HP: 350, acreHour: 100 },
  { brand: "Sampo-Rosenlew", model: "Comia C8", HP: 275, acreHour: 90 },
  { brand: "Sampo-Rosenlew", model: "Comia C10", HP: 300, acreHour: 95 },

  // Rostselmash Combines
  { brand: "Rostselmash", model: "Torum 785", HP: 520, acreHour: 150 },
  { brand: "Rostselmash", model: "Acros 595 Plus", HP: 350, acreHour: 100 },
  { brand: "Rostselmash", model: "RSM 161", HP: 380, acreHour: 110 },
  { brand: "Rostselmash", model: "Vector 410", HP: 250, acreHour: 85 },
  { brand: "Rostselmash", model: "Nova 340", HP: 220, acreHour: 75 },
  { brand: "Rostselmash", model: "Torum 785", HP: 520, acreHour: 150 },
  { brand: "Rostselmash", model: "Acros 595 Plus", HP: 350, acreHour: 100 },
  { brand: "Rostselmash", model: "RSM 161", HP: 380, acreHour: 110 },
  { brand: "Rostselmash", model: "Vector 410", HP: 250, acreHour: 85 },
  { brand: "Rostselmash", model: "Nova 340", HP: 220, acreHour: 75 },
];

const Swathers = [
  // John Deere Swathers
  { brand: "John Deere", model: "W260", HP: 260, acreHour: 45 },
  { brand: "John Deere", model: "W270", HP: 270, acreHour: 50 },
  { brand: "John Deere", model: "W290", HP: 290, acreHour: 55 },
  { brand: "John Deere", model: "W330", HP: 330, acreHour: 60 },
  { brand: "John Deere", model: "W340", HP: 340, acreHour: 65 },
  { brand: "John Deere", model: "W150", HP: 150, acreHour: 35 },
  { brand: "John Deere", model: "W170", HP: 170, acreHour: 40 },
  { brand: "John Deere", model: "W200", HP: 200, acreHour: 45 },
  { brand: "John Deere", model: "W220", HP: 220, acreHour: 50 },
  { brand: "John Deere", model: "W230", HP: 230, acreHour: 55 },

  // Case IH Swathers
  { brand: "Case IH", model: "WS-345", HP: 345, acreHour: 55 },
  { brand: "Case IH", model: "WS-275", HP: 275, acreHour: 50 },
  { brand: "Case IH", model: "WS-425", HP: 425, acreHour: 60 },
  { brand: "Case IH", model: "WS-375", HP: 375, acreHour: 55 },
  { brand: "Case IH", model: "WS-400", HP: 400, acreHour: 60 },
  { brand: "Case IH", model: "WS-320", HP: 320, acreHour: 50 },
  { brand: "Case IH", model: "WS-290", HP: 290, acreHour: 45 },
  { brand: "Case IH", model: "WS-350", HP: 350, acreHour: 55 },
  { brand: "Case IH", model: "WS-375", HP: 375, acreHour: 60 },
  { brand: "Case IH", model: "WS-325", HP: 325, acreHour: 50 },

  // New Holland Swathers
  { brand: "New Holland", model: "FR450", HP: 450, acreHour: 60 },
  { brand: "New Holland", model: "FR500", HP: 500, acreHour: 65 },
  { brand: "New Holland", model: "FR650", HP: 650, acreHour: 70 },
  { brand: "New Holland", model: "FR850", HP: 850, acreHour: 75 },
  { brand: "New Holland", model: "FR750", HP: 750, acreHour: 70 },
  { brand: "New Holland", model: "FR550", HP: 550, acreHour: 60 },
  { brand: "New Holland", model: "FR600", HP: 600, acreHour: 65 },
  { brand: "New Holland", model: "FR700", HP: 700, acreHour: 70 },
  { brand: "New Holland", model: "FR850", HP: 850, acreHour: 75 },
  { brand: "New Holland", model: "FR600", HP: 600, acreHour: 65 },

  // Claas Swathers
  { brand: "Claas", model: "Lexion 770", HP: 770, acreHour: 75 },
  { brand: "Claas", model: "Lexion 800", HP: 800, acreHour: 80 },
  { brand: "Claas", model: "Lexion 850", HP: 850, acreHour: 85 },
  { brand: "Claas", model: "Lexion 700", HP: 700, acreHour: 70 },
  { brand: "Claas", model: "Lexion 680", HP: 680, acreHour: 65 },
  { brand: "Claas", model: "Lexion 770", HP: 770, acreHour: 75 },
  { brand: "Claas", model: "Lexion 800", HP: 800, acreHour: 80 },
  { brand: "Claas", model: "Lexion 850", HP: 850, acreHour: 85 },
  { brand: "Claas", model: "Lexion 700", HP: 700, acreHour: 70 },
  { brand: "Claas", model: "Lexion 680", HP: 680, acreHour: 65 },

  // Massey Ferguson Swathers
  { brand: "Massey Ferguson", model: "MF 9270", HP: 270, acreHour: 50 },
  { brand: "Massey Ferguson", model: "MF 9500", HP: 300, acreHour: 55 },
  { brand: "Massey Ferguson", model: "MF 9600", HP: 320, acreHour: 60 },
  { brand: "Massey Ferguson", model: "MF 9500", HP: 300, acreHour: 55 },
  { brand: "Massey Ferguson", model: "MF 9700", HP: 320, acreHour: 60 },
  { brand: "Massey Ferguson", model: "MF 9800", HP: 350, acreHour: 65 },
  { brand: "Massey Ferguson", model: "MF 9270", HP: 270, acreHour: 50 },
  { brand: "Massey Ferguson", model: "MF 9500", HP: 300, acreHour: 55 },
  { brand: "Massey Ferguson", model: "MF 9600", HP: 320, acreHour: 60 },
  { brand: "Massey Ferguson", model: "MF 9700", HP: 320, acreHour: 60 },

  // Gleaner Swathers
  { brand: "Gleaner", model: "S77", HP: 320, acreHour: 50 },
  { brand: "Gleaner", model: "S78", HP: 340, acreHour: 55 },
  { brand: "Gleaner", model: "S88", HP: 360, acreHour: 60 },
  { brand: "Gleaner", model: "S96", HP: 380, acreHour: 65 },
  { brand: "Gleaner", model: "S98", HP: 400, acreHour: 70 },
  { brand: "Gleaner", model: "S77", HP: 320, acreHour: 50 },
  { brand: "Gleaner", model: "S78", HP: 340, acreHour: 55 },
  { brand: "Gleaner", model: "S88", HP: 360, acreHour: 60 },
  { brand: "Gleaner", model: "S96", HP: 380, acreHour: 65 },
  { brand: "Gleaner", model: "S98", HP: 400, acreHour: 70 },

  // Fendt Swathers
  { brand: "Fendt", model: "Ideal 7", HP: 451, acreHour: 55 },
  { brand: "Fendt", model: "Ideal 8", HP: 538, acreHour: 60 },
  { brand: "Fendt", model: "Ideal 9", HP: 647, acreHour: 65 },
  { brand: "Fendt", model: "Fendt 930", HP: 330, acreHour: 55 },
  { brand: "Fendt", model: "Fendt 720", HP: 275, acreHour: 50 },
  { brand: "Fendt", model: "Ideal 7", HP: 451, acreHour: 55 },
  { brand: "Fendt", model: "Ideal 8", HP: 538, acreHour: 60 },
  { brand: "Fendt", model: "Ideal 9", HP: 647, acreHour: 65 },
  { brand: "Fendt", model: "Fendt 930", HP: 330, acreHour: 55 },
  { brand: "Fendt", model: "Fendt 720", HP: 275, acreHour: 50 },

  // Laverda Swathers
  { brand: "Laverda", model: "M440", HP: 360, acreHour: 65 },
  { brand: "Laverda", model: "M450", HP: 380, acreHour: 70 },
  { brand: "Laverda", model: "M430", HP: 340, acreHour: 60 },
  { brand: "Laverda", model: "M420", HP: 320, acreHour: 55 },
  { brand: "Laverda", model: "M410", HP: 300, acreHour: 50 },
  { brand: "Laverda", model: "M440", HP: 360, acreHour: 65 },
  { brand: "Laverda", model: "M450", HP: 380, acreHour: 70 },
  { brand: "Laverda", model: "M430", HP: 340, acreHour: 60 },
  { brand: "Laverda", model: "M420", HP: 320, acreHour: 55 },
  { brand: "Laverda", model: "M410", HP: 300, acreHour: 50 },

  // Deutz-Fahr Swathers
  { brand: "Deutz-Fahr", model: "C9306", HP: 366, acreHour: 60 },
  { brand: "Deutz-Fahr", model: "C9206", HP: 340, acreHour: 55 },
  { brand: "Deutz-Fahr", model: "C9200", HP: 320, acreHour: 50 },
  { brand: "Deutz-Fahr", model: "C9300", HP: 350, acreHour: 55 },
  { brand: "Deutz-Fahr", model: "C9306", HP: 366, acreHour: 60 },
  { brand: "Deutz-Fahr", model: "C9206", HP: 340, acreHour: 55 },
  { brand: "Deutz-Fahr", model: "C9200", HP: 320, acreHour: 50 },
  { brand: "Deutz-Fahr", model: "C9300", HP: 350, acreHour: 55 },
  { brand: "Deutz-Fahr", model: "C9306", HP: 366, acreHour: 60 },
  { brand: "Deutz-Fahr", model: "C9206", HP: 340, acreHour: 55 },

  // Versatile Swathers
  { brand: "Versatile", model: "RT490", HP: 490, acreHour: 60 },
  { brand: "Versatile", model: "RT540", HP: 540, acreHour: 65 },
  { brand: "Versatile", model: "RT600", HP: 600, acreHour: 70 },
  { brand: "Versatile", model: "RT650", HP: 650, acreHour: 75 },
  { brand: "Versatile", model: "RT490", HP: 490, acreHour: 60 },
  { brand: "Versatile", model: "RT540", HP: 540, acreHour: 65 },
  { brand: "Versatile", model: "RT600", HP: 600, acreHour: 70 },
  { brand: "Versatile", model: "RT650", HP: 650, acreHour: 75 },
  { brand: "Versatile", model: "RT490", HP: 490, acreHour: 60 },
  { brand: "Versatile", model: "RT540", HP: 540, acreHour: 65 },

  // Sampo-Rosenlew Swathers
  { brand: "Sampo-Rosenlew", model: "Comia C8", HP: 275, acreHour: 45 },
  { brand: "Sampo-Rosenlew", model: "Comia C10", HP: 300, acreHour: 50 },
  { brand: "Sampo-Rosenlew", model: "Comia C12", HP: 350, acreHour: 55 },
  { brand: "Sampo-Rosenlew", model: "Comia C14", HP: 400, acreHour: 60 },
  { brand: "Sampo-Rosenlew", model: "Comia C8", HP: 275, acreHour: 45 },
  { brand: "Sampo-Rosenlew", model: "Comia C10", HP: 300, acreHour: 50 },
  { brand: "Sampo-Rosenlew", model: "Comia C12", HP: 350, acreHour: 55 },
  { brand: "Sampo-Rosenlew", model: "Comia C14", HP: 400, acreHour: 60 },
  { brand: "Sampo-Rosenlew", model: "Comia C8", HP: 275, acreHour: 45 },
  { brand: "Sampo-Rosenlew", model: "Comia C10", HP: 300, acreHour: 50 },

  // Rostselmash Swathers
  { brand: "Rostselmash", model: "RSM 161", HP: 380, acreHour: 55 },
  { brand: "Rostselmash", model: "RSM 161", HP: 380, acreHour: 55 },
  { brand: "Rostselmash", model: "Acros 595 Plus", HP: 350, acreHour: 60 },
  { brand: "Rostselmash", model: "Torum 785", HP: 520, acreHour: 65 },
  { brand: "Rostselmash", model: "Vector 410", HP: 250, acreHour: 50 },
  { brand: "Rostselmash", model: "Nova 340", HP: 220, acreHour: 45 },
  { brand: "Rostselmash", model: "RSM 161", HP: 380, acreHour: 55 },
  { brand: "Rostselmash", model: "RSM 161", HP: 380, acreHour: 55 },
  { brand: "Rostselmash", model: "Acros 595 Plus", HP: 350, acreHour: 60 },
  { brand: "Rostselmash", model: "Torum 785", HP: 520, acreHour: 65 },
];

// export { Planters, Tractors, Swathers, Combines, Sprayers };
const MachineTemplates = {
  PLANTER: Planters,
  TRACTOR: Tractors,
  SWATHER: Swathers,
  COMBINE: Combines,
  SPRAYER: Sprayers,
};
export default MachineTemplates;
