import { CropYearContext, FarmContext } from "../../../App";
import { FertilizerTypes } from "../../../Assets/DataTypes";
import { useContext, useEffect, useState } from "react";
import MultiChoiceQuestion from "../../FormInputElements/MultiChoiceQuestion";
import FertilizerTableField from "../../../Components/FormInputElements/FertilizerTableField";

export default function FertilizerRatesForm({}) {
  const cropyearContext = useContext(CropYearContext);
  const farmContext = useContext(FarmContext);
  const [fertilizersUsed, setFertilizersUsed] = useState({});

  useEffect(() => {
    // let cpyFertilizersUsed = {...fertilizersUsed}
    let cpy = {};

    // return;

    for (let FERT of ["N", "P", "K", "S", "M"]) {
      let rates = cropyearContext.state.fieldOperations.fertilizerRates[FERT];

      cpy[FERT] =
        rates["preSeed"] !== "0" ||
        rates["withSeed"] !== "0" ||
        rates["postSeed"] !== "0";
      setFertilizersUsed(cpy);
    }
  }, [cropyearContext.state]);

  function handleCheckmark(event, key) {
    let entry = {};
    entry[key] = event.target.checked;

    setFertilizersUsed({ ...fertilizersUsed, ...entry });
  }

  function handleFertilizerRate(target, key, seedStage) {
    const newValue = {};
    newValue[seedStage] = target;

    // Create a copy with updated value for the corresponding Fertilizer Rate Update
    const updatedFertilizerRate = {
      ...cropyearContext.state.fieldOperations.fertilizerRates[key],
      ...newValue,
    };

    // Create a copy of the fertilizerRate Object and override data inplace
    const updatedFertilizerRateObj = {
      ...cropyearContext.state.fieldOperations.fertilizerRates,
    };
    updatedFertilizerRateObj[key] = updatedFertilizerRate;

    // Create copy of current state fieldOperations and assign new fertilizerRate Object
    const updatedFieldOperations = {
      ...cropyearContext.state.fieldOperations,
      fertilizerRates: updatedFertilizerRateObj,
    };

    // Update cropyearContext with new fertilizerRates object
    cropyearContext.setter({
      ...cropyearContext.state,
      fieldOperations: updatedFieldOperations,
    });
  }

  return (
    <section
      style={{
        height: fertilizersUsed ? "auto" : "0",
        padingBottom: fertilizersUsed ? "20px" : "0",
      }}
      className={`transition-all duration-300  w-full  overflow-hidden`}
    >
      <h4 className="text-[rgb(102,102,102)] font-extralight text-[36px]">
        Fertilizer Rates (lbs/acre)
      </h4>
      <MultiChoiceQuestion
        valuesArray={FertilizerTypes}
        boxesState={fertilizersUsed}
        handleCheckmark={handleCheckmark}
        questionText={"Which Fertilizers did you apply during this Crop Year?"}
        modalTitle={"Fertilizer Rates"}
        modalDescription={
          "Please select the fertilizers applied to your field during this crop year and specifiy the corresponding rates."
        }
      />

      <ul>
        {FertilizerTypes.map((fertilizer, index) => {
          var key = fertilizer.value;

          var isChecked = fertilizersUsed[key];

          if (isChecked)
            return (
              <li key={key}>
                <FertilizerTableField
                  onChange={handleFertilizerRate}
                  fertilizerName={fertilizer.label}
                  elementCode={fertilizer.value}
                  fieldState={
                    cropyearContext.state.fieldOperations.fertilizerRates[
                      fertilizer.value
                    ]
                  }
                />
              </li>
            );
        })}
      </ul>
    </section>
  );
}
