import { CropYearContext, FarmContext } from "../../../App";
import { useContext, useEffect, useState } from "react";
import BooleanQuestion from "../../FormInputElements/BooleanQuestion";
import SelectMachineField from "./SelectMachineField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function FertilizerApplicationsForm() {
  const cropyearContext = useContext(CropYearContext);
  const farmContext = useContext(FarmContext);
  const [usedFertilizer, setUsedFertilizer] = useState(false);

  useEffect(() => {
    for (let phase of ["preSeed", "withSeed", "postSeed"]) {
      if (
        cropyearContext.state.fieldOperations.fertilizerApplications[phase]
          .machineId !== ""
      ) {
        setUsedFertilizer(true);
        break;
      }
    }
  }, [cropyearContext.state]);

  function handleFirstBooleanQuestion(e) {
    setUsedFertilizer(e.target.value === "true");
  }

  function handleMachineChange(machineId, seedStage) {
    let cpy = { ...cropyearContext.state };

    cpy.fieldOperations.fertilizerApplications[seedStage].machineId = machineId;

    console.log(machineId);
    cropyearContext.setter(cpy);
  }

  function handleHoursChange(hoursUsed, seedStage) {
    let cpy = { ...cropyearContext.state };

    cpy.fieldOperations.fertilizerApplications[seedStage].hoursUsed = hoursUsed;

    cropyearContext.setter(cpy);
  }

  function handleDateChange(date, seedStage) {
    let formattedDate = date.$d.toJSON().slice(0, 10);

    let cpy = { ...cropyearContext.state };

    cpy.fieldOperations.fertilizerApplications[seedStage].date = formattedDate;

    cropyearContext.setter(cpy);
  }

  return (
    <section className="pb-5">
      <h4 className="text-[rgb(102,102,102)] font-extralight text-[36px]">
        Fertilizer Application
      </h4>
      <BooleanQuestion
        handleChange={handleFirstBooleanQuestion}
        fieldValue={usedFertilizer}
        modalTitle={"Fertilizer"}
        modalDescription={
          "Was commercial fertilizer or manure applied for the analyzed crop?"
        }
        fieldLabel={
          "Did you apply fertilizer (either organic or inorganic source) this crop year?"
        }
      />
      <div
        style={{ height: usedFertilizer ? "272px" : "0" }}
        className={`transition-all duration-300  w-full  overflow-hidden`}
      >
        <p className="text-[#666666] mb-2">Tractors Used</p>
        {[
          { seedStage: "preSeed", label: "Pre Seed Tractor" },
          { seedStage: "withSeed", label: "Seed Tractor" },
          { seedStage: "postSeed", label: "Post Seed Tractor" },
        ].map((operation, index) => {
          const fieldState =
            cropyearContext.state.fieldOperations.fertilizerApplications[
              operation.seedStage
            ];

          // IF APPLIED FERTILIZER DURING PLANTING OPS  DON'T SHOW withSeed
          if (
            cropyearContext.state.fieldOperations.plantingOperation
              .appliedFertilizer &&
            operation.seedStage === "withSeed"
          )
            return;
          else
            return (
              <li className="flex " key={operation.seedStage}>
                <SelectMachineField
                  fieldLabel={"Tractor Used in Cultivation #" + (index + 1)}
                  machineType={"TRACTOR"}
                  fieldState={{
                    machineId:
                      cropyearContext.state.fieldOperations
                        .fertilizerApplications[operation.seedStage].machineId,
                    hoursUsed:
                      cropyearContext.state.fieldOperations
                        .fertilizerApplications[operation.seedStage].hoursUsed,
                  }}
                  handleHoursChange={(hoursUsed) =>
                    handleHoursChange(hoursUsed, operation.seedStage)
                  }
                  handleMachineChange={(machineId) =>
                    handleMachineChange(machineId, operation.seedStage)
                  }
                />

                {fieldState.date ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disableFuture
                      onChange={(t) => handleDateChange(t, operation.seedStage)}
                      // onChange={(t) => console.log}
                      label="Date"
                      sx={{ marginTop: "16px" }}
                      defaultValue={dayjs(fieldState.date)}
                    />
                  </LocalizationProvider>
                ) : (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disableFuture
                      sx={{ marginTop: "16px" }}
                      onChange={(t) => handleDateChange(t, operation.seedStage)}
                      // onChange={(t) => console.log}
                      label="Date"
                      defaultValue={
                        Number.isInteger(cropyearContext.state.crop.cropYear)
                          ? dayjs(
                              cropyearContext.state.crop.cropYear.toString()
                            )
                          : dayjs()
                      }
                    />
                  </LocalizationProvider>
                )}
              </li>
            );
        })}
      </div>
    </section>
  );
}
