import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

function GHGPieChart({ width, height, rawData }) {
  const canvasRef = useRef(null);

  function parseData(rawData) {
    const labels = [
      "Fieldwork GHG emissions",
      "Non-fieldwork GHG emissions",
      "N-fertilizer",
      "Crop residue",
      "Leaching",
      "Volatilization",
      "Nitrous oxide emissions",
    ];
    const values = [];

    for (const row of rawData) {
      values.push(row[0]);
    }
    return [labels, values];
  }

  useEffect(() => {
    const [labels, values] = parseData(rawData);

    const data = {
      labels: labels,
      datasets: [
        {
          label: "Dataset 1",
          data: values,
          backgroundColor: [
            "#4dc9f6",
            "#f67019",
            "#f53794",
            "#537bc4",
            "#acc236",
            "#166a8f",
            "#00a950",
            "#58595b",
            "#8549ba",
          ],
        },
      ],
    };

    const ctx = canvasRef.current.getContext("2d");

    const chart = new Chart(ctx, {
      type: "doughnut",
      data: data,
      options: {
        onClick: (e) => {
          const points = chart.getElementsAtEventForMode(
            e,
            "nearest",
            { intersect: true },
            false
          );

          if (points.length) {
            const firstPoint = points[0];
            const label = chart.data.labels[firstPoint.index];
            const value =
              chart.data.datasets[firstPoint.datasetIndex].data[
                firstPoint.index
              ];

            console.log(`Label: ${label}, Value: ${value}`);
          }
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [rawData]);

  return <canvas ref={canvasRef} width={width} height={height} />;
}

export default GHGPieChart;
