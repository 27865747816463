import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const COLORS = ["#E0582C", "#8FAADC", "#ED7D32", "#99CCFF"];

// [
//   [],
//   ["0.31", "1.86", "0.88", "1.10"],
//   ["0.87", "6.26", "2.69", "1.69"],
//   ["1.65", "1.02", "0.70", "3.05"],
//   ["2.82", "9.13", "4.28", "5.84"],
//   ["wind", "tillage", "tillage", "wind"],
// ];
function parseData(data) {
  if (!data) return;

  var names = data[0];
  var water = data[1];
  var tillage = data[2];
  var wind = data[3];
  var total = data[4];
  console.log(names);
  var formattedData = [];

  for (var i = 0; i < 4; i++) {
    formattedData.push({
      name: names.shift(),
      water: water.shift(),
      tillage: tillage.shift(),
      wind: wind.shift(),
      total: total.shift(),
    });
  }

  return formattedData;
}

export default class Chart extends PureComponent {
  render() {
    const formattedData = parseData(this.props.rawData);

    return (
      <ResponsiveContainer width={this.props.width} height={this.props.height}>
        <BarChart
          data={formattedData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />

          <Bar
            dataKey={"water"}
            fill="#99CCFF"
            activeBar={<Rectangle stroke="rgb(255,125,50)" strokeWidth={3} />}
          />
          <Bar
            dataKey={"tillage"}
            fill="#ED7D32"
            activeBar={<Rectangle stroke="rgb(255,125,50)" strokeWidth={3} />}
          />
          <Bar
            dataKey={"wind"}
            fill="#8FAADC"
            activeBar={<Rectangle stroke="rgb(255,125,50)" strokeWidth={3} />}
          />
          <Bar
            dataKey={"total"}
            fill="#E0582C"
            activeBar={<Rectangle stroke="rgb(255,125,50)" strokeWidth={3} />}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
