import React, { useState, useEffect, useContext } from "react";
import { axiosInstance } from "./axiosFetchers";
import { useAuth } from "../Components/Auth/useAuth";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import SupportIcon from "../Assets/Icons/SupportIcon";
import FarmIcon from "../Assets/Icons/FarmIcon";
import FieldIcon from "../Assets/Icons/FieldIcon";
import AddFarmFieldIcon from "../Assets/Icons/AddFarmFieldIcon";
import DashBoardIcon from "../Assets/Icons/DashBoardIcon";
import FieldLibraryIcon from "../Assets/Icons/FieldLibraryIcon";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import { GUI_CONTEXT } from "../App";
import Button from "@mui/material/Button";

function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

export default function SideNav() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const sideNavContext = useContext(GUI_CONTEXT);

  const [menu, setMenu] = useState([]);

  useEffect(() => {}, [sideNavContext.state]);

  // if single level can have either onClick OR to
  // if Multi Level it can have both an OnCLcik AND to
  // if Multi level and doesn't have a TO it means it is ROOT

  const MenuItem = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} />;
  };

  const SingleLevel = ({ item }) => {
    const borderStyle =
      "borderColor" in item
        ? {
            borderBottom: `1px solid ${item.borderColor}`,
            "&:hover": {
              borderBottom: `2px solid ${item.borderColor}`,
            },
          }
        : {};
    return (
      <Button
        sx={{
          textTransform: "capitalize",
          ...borderStyle,
        }}
      >
        {" "}
        <ListItem
          onClick={() => {
            "onClick" in item ? item.onClick() : navigate(item.to);
          }}
        >
          {item.icon === "collapse" && (
            <ListItemIcon>
              {sideNavContext.state.horizontallyCollapsed ? (
                <KeyboardArrowRightIcon className="text-white" />
              ) : (
                <KeyboardArrowLeftIcon className="text-white" />
              )}
            </ListItemIcon>
          )}
          {item.icon !== "collapse" && <ListItemIcon>{item.icon}</ListItemIcon>}
          {!sideNavContext.state.horizontallyCollapsed && (
            <ListItemText className="text-white" primary={item.title} />
          )}
        </ListItem>
      </Button>
    );
  };

  const MultiLevel = ({ item }) => {
    const { items: children } = item;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };
    const borderStyle =
      "borderColor" in item
        ? {
            borderBottom: `1px solid ${item.borderColor}`,
            "&:hover": {
              borderBottom: `2px solid ${item.borderColor}`,
            },
          }
        : {};
    return (
      <React.Fragment>
        <Button
          sx={{
            textTransform: "capitalize",
            color: "#FFF",
            width: "100%",
            ...borderStyle,
          }}
        >
          <ListItem className="w-full p-0 ">
            <ListItemIcon
              onClick={() => {
                "to" in item ? navigate(item.to) : handleClick();
              }}
            >
              {item.icon}
            </ListItemIcon>

            {!sideNavContext.state.horizontallyCollapsed && (
              <ListItemText
                onClick={() => {
                  "to" in item ? navigate(item.to) : handleClick();
                }}
                primary={item.title}
              />
            )}
            {!sideNavContext.state.horizontallyCollapsed ? (
              open ? (
                <ExpandLessIcon className="text-white" onClick={handleClick} />
              ) : (
                <ExpandMoreIcon className="text-white" onClick={handleClick} />
              )
            ) : (
              ""
            )}
          </ListItem>
        </Button>
        {!sideNavContext.state.horizontallyCollapsed && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List
              component="div"
              style={{ paddingLeft: "25px", bg: "rgb(250,0,0)" }}
            >
              {children.map((child, key) => (
                <MenuItem key={key} item={child} />
              ))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="h-full  max-w-[325px] bg-[rgb(36,39,41)] pt-4">
      <Collapse
        style={{ height: "100%" }}
        orientation="horizontal"
        in={!sideNavContext.state.horizontallyCollapsed}
        collapsedSize={62}
      >
        <ul className={`w-[325px] overflow-y-scroll h-full flex flex-col`}>
          {sideNavContext.state.menu.map((item, key) => (
            <MenuItem key={key} item={item} />
          ))}
        </ul>
      </Collapse>
    </div>
  );
}
// export default MultiLevel;
